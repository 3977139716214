<template>
    <div class="word-dialog">
        <el-dialog @close="onClose" top="100px" @open="onOpen" :visible.sync="dialogVisible" width="50%">
            <div slot="title">
                <h3 class="lt">{{ info.word }}</h3>
            </div>
            <div class="flex flex-v-center">
                <div class="flex flex-v-center pointer" @click="play(1)">
                    <div class="bold">UK [{{ info.phonetic ? (info.phonetic[0] ? info.phonetic[0].toString() : "") : '-'
                        }}]
                    </div>
                    <el-image class="margin-x" src="/web/image/playing.png"></el-image>
                </div>
                <div class="flex flex-v-center margin-x pointer" @click="play(2)">
                    <div class="bold">US [{{ info.phonetic ? (info.phonetic[1] ? info.phonetic[1].toString() : "") : '-'
                        }}]
                    </div>
                    <el-image class="margin-x" src="/web/image/playing.png"></el-image>
                </div>
            </div>
            <el-divider></el-divider>
            <h4 class="mt"><el-tag size="small">{{ $t('词典释义') }}</el-tag></h4>
            <div class="mt margin-st" v-for="(row, index) in info.explanation.split('\n')" :key="index">{{ row }}</div>
            <div slot="footer" class="dialog-footer flex flex-v-center flex-h-end">
                <el-button class="margin-x" v-if="type == 0" icon="el-icon-close" type="danger"
                    @click="dialogVisible = false" round size="small">{{ $t('未掌握') }}</el-button>
                <el-button class="margin-x" v-if="type == 0" icon="el-icon-check" type="success" @click="addRecord"
                    round size="small">{{ $t('已掌握') }}</el-button>

                <el-select size="mini" v-model="book_id" style="width:100px;">
                    <el-option v-for="(item, index) in books" :key="index" :value="item.id"
                        :label="item.title"></el-option>
                </el-select>
                <el-divider direction="vertical"></el-divider>
                <el-rate v-model="stars" @change="changStars"></el-rate>
                <el-divider direction="vertical"></el-divider>
                <el-button v-if="type == 1" icon="el-icon-circle-plus-outline" type="primary" @click="addMine" round
                    size="mini">{{ $t('加入单词本') }}</el-button>
            </div>


            <div v-if="info.phrase" class="margin-ts">
                <h2><el-tag size="small">{{ $t('短语') }}</el-tag></h2>
                <div class="mt" v-for="(line, index) in info.phrase" :key="index">
                    <div v-for="(row, index1) in line.split('\n')" :key="index1">
                        <div v-if="(row.toLowerCase().indexOf(info.word) != -1)" style="word-break: keep-all;">
                            <span class="margin-rs">{{ index + 1 }}.</span>
                            <span>{{ row.substring(0, row.toLowerCase().indexOf(info.word)) }}</span>
                            <span class="bold1">{{
                                row.substr(row.toLowerCase().indexOf(info.word), info.word.length)
                                }}</span>

                            <span>{{
                                row.substr(row.toLowerCase().indexOf(info.word) + info.word.length)
                                }}</span>
                        </div>
                        <div v-else>
                            {{ row }}
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="info.sentence" class="margin-ts">
                <h4 class="mt">{{ $t('相关例句') }}</h4>
                <div class="mt" v-for="(row, index) in info.sentence.split('\n')" :key="index">
                    <div v-if="(row.toLowerCase().indexOf(info.word) != -1)" style="word-break: keep-all;">
                        <span>{{ row.substring(0, row.toLowerCase().indexOf(info.word)) }}</span>
                        <span class="bold1">{{
                            row.substr(row.toLowerCase().indexOf(info.word), info.word.length)
                            }}</span>

                        <span>{{
                            row.substr(row.toLowerCase().indexOf(info.word) + info.word.length)
                            }}</span>
                    </div>
                    <div v-else>
                        {{ row }}
                    </div>
                </div>
            </div>

            <div v-else-if="info.example_sentence" class="margin-ts">
                <h4 class="mt"><el-tag size="small">{{ $t('例句') }}</el-tag></h4>
                <div class="mt" v-for="(line, index) in info.example_sentence" :key="index">
                    <div v-for="(row, index1) in line.split('\n')" :key="index1">
                        <div v-if="(row.toLowerCase().indexOf(info.word) != -1)" style="word-break: keep-all;">
                            <span class="margin-rs">{{ index + 1 }}.</span>
                            <span>{{ row.substring(0, row.toLowerCase().indexOf(info.word)) }}</span>
                            <span class="bold1">{{
                                row.substr(row.toLowerCase().indexOf(info.word), info.word.length)
                                }}</span>

                            <span>{{
                                row.substr(row.toLowerCase().indexOf(info.word) + info.word.length)
                                }}</span>
                        </div>
                        <div v-else>
                            {{ row }}
                        </div>
                    </div>
                </div>
            </div>



        </el-dialog>
        <audio controls ref="audio" style="display:none;">
            <source :src="src" />
        </audio>
    </div>
</template>

<script>

export default {
    name: "wordDialog",
    data() {
        return {
            info: { explanation: "" },
            dialogVisible: false,
            src: "",
            searching: false,
            books: [{ title: '默认', id: 0 }],
            book_id: 0,
            stars: 1,
            from_type: "",
        };
    },
    props: {
        type: {
            type: Number,
            default: 0,
        },
    },

    created() {
        if (this.$store.getters.getRole() > 0) {
            this.$http.post("/api/book/list").then((books) => {
                this.books = this.books.concat(books);
            })
        }
    },

    methods: {

        onOpen() {
            this.$store.commit("setWordDialogShow", true);
        },

        onClose() {
            this.$store.commit("setWordDialogShow", false);
            // this.$bus.$emit("word-reset")
        },

        changStars() {
            if (this.$store.getters.getRole() == 0 && this.stars > 1) {
                this.$vip_alert("此功能");
                setTimeout(() => {
                    this.stars = 0;
                }, 500);
            }
        },
        show(word, from_type) {
            this.from_type = from_type;
            word = word.replace(/\s+/g, ' ').replace(/[’']s/, "");
            if (this.searching) return;
            this.searching = true;

            let info = this.$store.getters.getWord(word);
            if (info) {
                this.info = info;
                if (info.mine) {
                    this.stars = info.mine.stars;
                    this.book_id = info.mine.book_id;
                } else {
                    this.stars = 0;
                    this.book_id = 0;
                }
                this.dialogVisible = true;
                this.searching = false;
            } else {
                this.$http.post("/api/word/info", { word: word }).then((info) => {
                    this.info = info;
                    this.$store.commit("addWord", info);
                    if (info.mine) {
                        this.stars = info.mine.stars;
                        this.book_id = info.mine.book_id;
                    } else {
                        this.stars = 0;
                        this.book_id = 0;
                    }

                    this.dialogVisible = true;
                    this.searching = false;
                }).catch(() => {
                    this.searching = false;
                });
            }
        },

        play(type) {
            this.src = this.$host + "/api/word/audio/" + this.info.word + "/" + type
            this.$refs.audio.src = this.src;
            this.$refs.audio.play();
        },

        addRecord() {
            this.$http.post("/api/word/record", { word: this.info.word }).then((word) => {
                this.$bus.$emit("update_word", word);
                this.$message({
                    message: this.$t('提交成功'),
                    type: 'success'
                });
            });
        },

        addMine() {
            if (this.stars > 0) {
                this.$http.post("/api/word/mine", { word: this.info.word, from_type: this.from_type, book_id: this.book_id, stars: this.stars }).then((mine) => {
                    this.info["mine"] = mine;
                    this.$store.commit("updateWord", this.info);
                    this.$message({
                        message: this.$t('提交成功'),
                        type: 'success',
                    });
                });
            }

        },
    },
};
</script>
<style scoped>
.word-dialog>>>.el-dialog__body {
    padding-top: 0;
}

.drawer {
    padding: 30px 13%;
}

.drawer>>>.el-input__inner {
    border-radius: 20px;
    border-color: #eeeeee;
}

.bold1 {
    font-weight: bold;
    color: red;
    font-size: 16px;
    text-decoration: underline;
}
</style>
