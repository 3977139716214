<template>
    <div>
        <el-dialog center :visible.sync="dialogVisible" top="15%" width="600px">
            <div slot="title">
                <h4>{{$t('题目报错')}}</h4>
            </div>

            <el-form ref="form" size="small" :model="reviewForm" label-width="80px">
                <el-form-item :label="`${$t('问题类型')}:`">
                    <el-checkbox-group v-model="reviewForm.tag">
                        <el-checkbox :label="$t('题目文本问题')"></el-checkbox>
                        <el-checkbox :label="$t('音频问题')"></el-checkbox>
                        <el-checkbox :label="$t('答案问题')"></el-checkbox>
                        <el-checkbox :label="$t('没有更新')"></el-checkbox>
                        <el-checkbox :label="$t('评论区问题')"></el-checkbox>
                        <el-checkbox :label="$t('其他问题')"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="">
                    <el-input type="textarea" :rows="3" v-model="reviewForm.content" :placeholder="$t('请输入反馈内容')"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer">
                <el-button round style="width:200px" type="primary" @click="reviewSubmit">{{$t('提交')}}</el-button>
            </div>

        </el-dialog>
    </div>
</template>
  
<script>
export default {
    name: "reviewDialog",
    data() {
        return {
            dialogVisible: false,
            source_id: 0,
            reviewForm: { tag: [], content: "" },
        };
    },
    methods: {
        show(source_id) {
            this.source_id = source_id;
            this.dialogVisible = true;
        },

        reviewSubmit() {
            console.log(this.reviewForm);
            if (this.reviewForm.tag == "") {
                this.$message({
                    message: this.$t('请选择问题类型') +'!',
                    type: 'primary'
                });
            } else if (this.reviewForm.content == "") {
                this.$message({
                    message: this.$t('请选择输入反馈内容') + '!',
                    type: 'primary'
                });
            } else {
                this.reviewForm["source_id"] = this.source_id;
                this.$http.post("/api/source/review", this.reviewForm).then(() => {
                    this.dialogVisible = false;
                    this.$message({
                        message: this.$t('提交成功，感谢您的支持') + '！',
                        type: 'success'
                    });
                })
            }
        },
    },
};
</script>
<style scoped>

</style>
  